import React, { useState } from 'react';
import HyperLink from 'components/Core/HyperLink';
import { Col, Row } from 'components/Core/Grid';
import { WithUserContext } from 'utils/UserContext';
import {
  ToolboxBookmarksLayout,
  TookboxBookmarksTitle,
  CloseButton,
  BookmarksCol,
  RightCol,
} from './styles';
import BookmarksMenu from '../BookmarksMenu';
import BookmarkCards from '../BookmarkCards';

const BookmarksContent = ({ title = 'Bookmarks', userInfo, prevPath }) => {
  const [filterValue, setFilterValue] = useState('');

  const onFilter = (value) => {
    setFilterValue(value);
  };

  const bookmarkData =
    filterValue && userInfo?.bookmarks
      ? userInfo.bookmarks.filter((x) => x.type === filterValue)
      : userInfo?.bookmarks;

  return (
    <ToolboxBookmarksLayout>
      <Row>
        <Col xs={4}>
          <TookboxBookmarksTitle>{title}</TookboxBookmarksTitle>
        </Col>
        <Col xs={18} />
        <RightCol xs={2}>
          <HyperLink href={prevPath}>
            <CloseButton>X</CloseButton>
          </HyperLink>
        </RightCol>
      </Row>
      <Row>
        <Col xs={24}>
          <BookmarksMenu onClick={onFilter} selected={filterValue} />
        </Col>
        <BookmarksCol md={24}>
          <BookmarkCards data={bookmarkData} />
        </BookmarksCol>
      </Row>
    </ToolboxBookmarksLayout>
  );
};

export default WithUserContext(BookmarksContent);
